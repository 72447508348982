/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function resizeFrame () {          
                       
  viewportHeight = $(window).height();
  viewportWidth = $(window).width(); 

  if ($("body.admin-bar").length > 0) {
    adminbar = $("#wpadminbar").height();
  } else {
    adminbar = 0;
  }


$('#fullwidth-slider').css("height", viewportHeight - $("header").height() - adminbar);
  if (viewportWidth >= 992) {
    
  } else { 
    //$('#fullwidth-slider').css("height", "");
  }
}

$.event.add(window, "load", resizeFrame);
$.event.add(window, "resize", resizeFrame);
$.event.add(window, "scroll", resizeFrame);

var $myGroup = $('#accordion');
$myGroup.on('show.bs.collapse','.collapse', function(e) {

    $myGroup.find('.collapse.in').collapse('hide');

    var visible = $(this);
    var reversedIndex = (visible.parent().parent().find(".panel").length -visible.parent().index())-1;

    $("#featured").zAccordion("trigger", reversedIndex);

});

$("#featured").zAccordion({
  easing: "easeInOutCubic",
  speed: 350,
  width: "100%",
  height: 700,
  auto: false,
  tabWidth: "9%",
  slideClass: "frame",
  startingSlide: 4,
  buildComplete: function() {

    $('#featured').find('li.frame-open').find(".title").hide();

  },
  animationStart: function() {
  
    $('#featured').find('li.frame-open').find(".title").fadeOut("fast");
    $('#featured').find('li.frame-closed').find(".title").fadeIn("fast");
  
  },
  animationComplete: function () {

    var visible = $('#featured').find('li.frame-open').parent().children().index(this);
    visible = parseInt(visible) +1;

    $('#accordion .panel .panel-collapse').collapse('hide');
    $('#accordion .panel:eq( -' + parseInt(visible) + ' ) .panel-collapse').collapse('show');

  }
});

$('.cd-nav-trigger').on('click', function(event){
  event.preventDefault();
  if( $('#bs-example-navbar-collapse-1').hasClass('in') ) {
    $(this).removeClass('nav-is-visible');
    $('.navbar-collapse').collapse('toggle');
  } else {
    $(this).addClass('nav-is-visible');
    $('.navbar-collapse').collapse('toggle');
  }
});

//   Scroll to Links  #   
$('a.scrollToLink[href^="#"]').bind('click.smoothscroll',function (e) {
    e.preventDefault();
    var target = this.hash, $target = $(target), offset = 1;

    if (target === '#header' && $("body.admin-bar").length !== '') {
      offset = 40;
    }

    $('html, body').stop().animate({'scrollTop': $target.offset().top-offset}, 1500, 'easeInOutExpo'); 
});


function preload(arrayOfImages) {
    $(arrayOfImages).each(function(){
        $('<img/>')[0].src = this;
        // Alternatively you could use:
        // (new Image()).src = this;
    });
}

$(window).trigger('draw');

$(window).resize(function() {
  $(this).trigger('draw');
});